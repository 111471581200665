import React, { useContext, Suspense, useCallback } from "react";

import {
  AuthContext,
  AuthStatus,
  AuthContextType,
} from "../shared/auth/AuthProvider";
import { useContextPropertyValue } from "../shared/utils/hooks";
import { LoadingScreen } from "../components/LoadingScreen";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));

const setValidB2CRedirectUriThenLogin = (auth: AuthContextType): void => {
  /* eslint-disable no-restricted-globals */
  history.replaceState(null, "", "/");
  auth.signin();
};

const InitializedApp = () => {
  const auth = useContext(AuthContext);
  const isRedirectingToForgotPassword = auth.hasForgottenPassword;

  if (auth.status === "signedin") {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <AuthenticatedApp />
      </Suspense>
    );
  }

  if (!isRedirectingToForgotPassword) {
    setValidB2CRedirectUriThenLogin(auth);
  }

  return <Suspense fallback={<LoadingScreen />} />;
};

export function App() {
  const initialized = useContextPropertyValue(
    AuthContext,
    "status",
    (status: AuthStatus) => status !== "uninitialized",
  );

  const Initialized = useCallback(
    () => (!initialized ? <LoadingScreen /> : <InitializedApp />),
    [initialized],
  );

  return <Initialized />;
}
