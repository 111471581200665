import React, { FunctionComponent } from "react";
import baseStyled, {
  createGlobalStyle,
  ThemedStyledInterface,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import MontserratBold from "./Montserrat-Bold.ttf";
import MontserratRegular from "./Montserrat-Regular.ttf";
import ProximaNovaReg from "./ProximaNovaReg.woff2";
import ProximaNovaLight from "./ProximaNovaLight.woff2";
import crossRed from "./icons/Cross-red.svg";
import crossWhite from "./icons/Cross-white.svg";
import menu from "./icons/Menu.svg";
import checkGreen from "./icons/Check-green.svg";
import checkWhite from "./icons/Check-white.svg";
import calendar from "./icons/Calendar.svg";
import logoFull from "./AireonLogo-White_R.svg";
import logoText from "./AireonText-White_R.svg";
import logoIcon from "./AireonCircle-White.svg";
import logoFullColor from "./AireonLogo_R.svg";
import expandArrow from "./icons/Expand-arrow.svg";
import expandArrowBlue from "./icons/expandArrowBlue.svg";
import user from "./icons/User.svg";
import welcomeBackground from "./Aireon_Illustration_Ocean.jpg";
import forbiddenBackground from "./Grounded-planes_L.png";

export const aireonTheme = {
  color: {
    primary: {
      spaceBlue: "#005fae",
      nightSky: "#192954",
      rocketGrey: "#58585b",
      rocketGreyRgb: "88, 88, 91",
      variant: {
        rocketGreyLight48: "rgba(88, 88, 91, 0.48)",
        rocketGreyLight20: "rgba(88, 88, 91, 0.20)",
        rocketGreyLight06: "rgba(88, 88, 91, 0.06)",
      },
    },
    secondary: {
      blueMarble: "#4bc7ec",
      ignition: "#ff9000",
      starBright: "#f2d844",
      terraFirma: "#a7c539",
      veryViolet: "#8228eb",
      shadowyRedLips: "#B41E1E",
    },
    utility: {
      error: "#ed1c24",
      success: "#1bb934",
      info: "#dfe3e9",
      disabled: "#e9edf1",
      blueHighlight: "#2196f3",
      blueHighlightRgb: "33, 150, 243",
    },
    greyScale: {
      mediumGrey: "#ccc",
      darkGrey: "#9f9f9f",
      lightGrey: "#ddd",
      lightestGrey: "#f6f6f6",
      white: "#fff",
      whiteRgb: "255, 255, 255",
      geminiGrey: "#707070",
      geminiGreyRgb: "129, 129, 129",
    },
  },
  typography: {
    headline: {
      typefaceSrc: MontserratBold,
      typefaceName: "MontserratBold",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "800",
      color: "#333",
    },
    body: {
      typefaceSrc: ProximaNovaReg,
      typefaceName: "ProximaNovaReg",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
      color: "#555",
      colorRgb: "85, 85, 85",
    },
    label: {
      typefaceSrc: ProximaNovaReg,
      fontFamily: "ProximaNovaReg, sans-serif",
      fontSize: "13px",
      lineHeight: "20px",
      fontWeight: "100",
      letterSpacing: "0.7px",
      color: "#9f9f9f",
    },
  },
  icon: {
    crossRed,
    crossWhite,
    checkGreen,
    checkWhite,
    calendar,
    user,
    menu,
    expandArrow,
    expandArrowBlue,
    logoText,
    logoIcon,
    logoFull,
    logoFullColor,
  },
  imagery: {
    welcomeBackground,
    forbiddenBackground,
  },
  drawer: {
    width: 300,
  },
};

export const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      light: aireonTheme.color.secondary.blueMarble,
      main: aireonTheme.color.primary.spaceBlue,
      dark: aireonTheme.color.primary.nightSky,
      contrastText: aireonTheme.color.greyScale.white,
    },
  },
  typography: {
    fontFamily: "ProximaNovaReg, sans-serif",
  },
});

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "ProximaNovaLight";
    src: url(${ProximaNovaLight});
  }
  @font-face {
    font-family: "ProximaNovaReg";
    src: url(${ProximaNovaReg});
  }
  @font-face {
    font-family: "MontserratRegular";
    src: url(${MontserratRegular});
  }  
  @font-face {
    font-family: "MontserratBold";
    src: url(${MontserratBold});
  }

  body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: ${aireonTheme.typography.body.typefaceName};
    font-size: ${aireonTheme.typography.body.fontSize};
    line-height: ${aireonTheme.typography.body.lineHeight};
    font-weight: ${aireonTheme.typography.body.fontWeight};
    color: ${aireonTheme.typography.body.color};    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
  }
`;

export type Theme = typeof aireonTheme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;

export const ThemeProvider: FunctionComponent = ({ children }) => (
  <StyledThemeProvider theme={aireonTheme}>
    <GlobalStyles />
    <MuiThemeProvider theme={defaultMaterialTheme}>{children}</MuiThemeProvider>
  </StyledThemeProvider>
);
