import { baseUrl, baseUrlSearch } from ".";
import Request from "./RequestMethods";

import {
  LiveSearchResponseType,
  LiveSearchReportResponseType,
} from "./types/LiveSearch";

export class LiveSearch extends Request {
  public static search(
    flightIdentifier: string,
    flightIdentifierValue: string | null,
    accessToken: string | null,
  ): Promise<LiveSearchResponseType> {
    return super.getApim<LiveSearchResponseType>(
      `${baseUrlSearch}/query?${flightIdentifier}=${flightIdentifierValue}`,
      accessToken,
    );
  }

  public static report(
    icao: string,
    lastTime: number | null,
    accessToken: string | null,
  ): Promise<LiveSearchReportResponseType[]> {
    return super.post<LiveSearchReportResponseType[]>(
      `${baseUrl}/alert/${icao}/report`,
      {
        lastTime: lastTime,
      },
      accessToken,
    );
  }
}
