export default class Http {
  private static JSON_CONTENT = { "Content-Type": "application/json" };

  private static JSON_CONTENT_WITH_APIM = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_APIM_KEY}`,
  };

  private static fetchJSON = <T>(
    uri: string,
    parameters: RequestInit,
  ): Promise<T> =>
    fetch(uri, parameters)
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw response;
      })
      .then((response) => response.json());

  private static getRequestHeader = (tokenId: string): Headers =>
    new Headers({
      ...Http.JSON_CONTENT,
      Authorization: `Bearer ${tokenId}`,
    });

  public static get = <T>(uri: string, tokenId: string | null): Promise<T> => {
    if (tokenId === null) {
      return Http.fetchJSON<T>(uri, {
        method: "GET",
        headers: Http.JSON_CONTENT,
      });
    }

    return Http.fetchJSON<T>(uri, {
      method: "GET",
      headers: Http.getRequestHeader(tokenId),
    });
  };

  private static getRequestHeaderApim = (tokenId: string): Headers =>
    new Headers({
      ...Http.JSON_CONTENT_WITH_APIM,
      Authorization: `Bearer ${tokenId}`,
    });

  public static getApim = <T>(
    uri: string,
    tokenId: string | null,
  ): Promise<T> => {
    if (tokenId === null) {
      return Http.fetchJSON<T>(uri, {
        method: "GET",
        headers: Http.JSON_CONTENT_WITH_APIM,
      });
    }

    return Http.fetchJSON<T>(uri, {
      method: "GET",
      headers: Http.getRequestHeaderApim(tokenId),
    });
  };

  public static post = <T>(
    uri: string,
    body: any,
    tokenId: string | null,
  ): Promise<T> => {
    if (tokenId === null) {
      return Http.fetchJSON<T>(uri, {
        method: "POST",
        headers: Http.JSON_CONTENT,
        body: JSON.stringify(body),
      });
    }

    return Http.fetchJSON<T>(uri, {
      method: "POST",
      headers: Http.getRequestHeader(tokenId),
      body: JSON.stringify(body),
    });
  };
}
