import Request from "./RequestMethods";
import { baseUrl } from "./index";

export type SearchDateRestrictions = {
  startDate: string;
  endDate: string;
};

export interface SearchRestrictionsResponse {
  dateRestrictions: SearchDateRestrictions[] | [];
  geoRestrictions: string[];
}

export class HistoricalSearch extends Request {
  public static userSearchRestrictions(
    accessToken: string | null,
  ): Promise<SearchRestrictionsResponse> {
    return super.get<SearchRestrictionsResponse>(
      `${baseUrl}/restrictions`,
      accessToken,
    );
  }
}
