import React from "react";
import { styled } from "./ThemeProvider";
import { Grid, CircularProgress } from "@material-ui/core";

const Div = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const GridContainer = styled(Grid)`
  height: 100vh;
`;

export function LoadingScreen() {
  return (
    <GridContainer container justify="center" alignItems="center">
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Div>
          <CircularProgress size={80} />
        </Div>
      </Grid>
      <Grid item xs={3} />
    </GridContainer>
  );
}
