import React from "react";
import { styled } from "./ThemeProvider";

interface InputProps {
  readonly variant: "primary" | "secondary" | "borderless";
}

const Input = styled.input<InputProps>`
  ${(props) => `
    text-align: center;
    font-family: ${props.theme.typography.body.typefaceName};
    letter-spacing: 0px;
    padding: 10px 16px 10px 16px;
    border-radius: 3px;
    font-size: 14px;
    margin-bottom: 6px;
    margin-top: 6px;
    transition: all 0.1s;
    box-sizing: border-box;
    cursor: pointer;
  `}
  ${(props) => {
    if (props.variant === "primary") {
      return `
        background-color: ${props.theme.color.greyScale.white};
        color: ${props.theme.color.primary.spaceBlue};
        border: 1px solid ${props.theme.color.primary.spaceBlue};
        :disabled {
          background-color: ${props.theme.color.utility.disabled};
          border-color: rgba(0, 0, 0, 0.1);
        }
        :active {
          background-color: ${props.theme.color.utility.disabled};
        }
        :focus {
          outline-color: ${props.theme.color.utility.blueHighlight};
        }`;
    }
    if (props.variant === "borderless") {
      return `
        background-color: ${props.theme.color.greyScale.white};
        color: ${props.theme.color.primary.spaceBlue};
        border: 0px;
        :disabled {
          background-color: ${props.theme.color.utility.disabled};
        }
        :active {
          color: ${props.theme.color.primary.nightSky};
        }
        :focus {
          outline-color: ${props.theme.color.utility.blueHighlight};
        }`;
    }
    return `
      color: ${props.theme.color.greyScale.white};
      background-color: ${props.theme.color.primary.spaceBlue};
      border: 1px solid ${props.theme.color.primary.spaceBlue};
      :disabled {
        background-color: ${props.theme.color.greyScale.darkGrey};
        border-color: rgba(0, 0, 0, 0.1);
        cursor: default;
      }
      :active {
        background-color: ${props.theme.color.utility.disabled};
        color: ${props.theme.color.primary.spaceBlue};
      }
      :focus {
        outline-color: ${props.theme.color.utility.blueHighlight};
      }`;
  }}
`;

type ButtonProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  "type" | "ref" | "as"
> & { variant?: "primary" | "secondary" | "borderless"; submit?: boolean };

export function Button({
  variant = "primary",
  submit = false,
  ...theRest
}: ButtonProps) {
  return (
    <Input type={submit ? "submit" : "button"} variant={variant} {...theRest} />
  );
}
