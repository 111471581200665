export enum EnvironmentCode {
  Dev = "DEV",
  Qa = "QA",
  Stg = "STG",
  Prd = "PRD",
}

export const ENV = {
  authority: process.env.REACT_APP_AUTHORITY!,
  clientId: process.env.REACT_APP_CLIENT_ID!,
  scopes: [process.env.REACT_APP_API_SCOPE!],
  refreshInterval: process.env.REACT_APP_TOKEN_REFRESH_INTERVAL_MS!,
  groupAlert: process.env.REACT_APP_ALLOWED_GROUP_ALERT!,
  groupQuery: process.env.REACT_APP_ALLOWED_GROUP_QUERY!,
};
