import { useContext, useState, useEffect } from "react";

export function useContextProperty<T, K extends keyof T>(
  context: React.Context<T>,
  property: K,
) {
  const contextType = useContext(context);
  const [propertyValue, setPropertyValue] = useState(
    () => contextType[property],
  );
  useEffect(() => {
    if (contextType[property] !== propertyValue) {
      setPropertyValue(() => contextType[property]);
    }
  }, [contextType, propertyValue, property]);
  return propertyValue;
}

export function useContextPropertyValue<T, K extends keyof T>(
  context: React.Context<T>,
  property: K,
  change: (value: T[K]) => boolean,
) {
  const contextProperty = useContextProperty(context, property);
  const [equal, setEqual] = useState<boolean>(false);
  useEffect(() => {
    if (equal !== change(contextProperty)) {
      setEqual(!equal);
    }
  }, [contextProperty, equal, change]);
  return equal;
}
