import decode from "jwt-decode";

export type Profile = {
  apiId: string;
  issuer: string;
  issueDateTime: Date;
  notBeforeDateTime: Date;
  expiryDateTime: Date;
  familyName: string;
  givenName: string;
  // name: string;
  // uniqueName: string;
  email: string | undefined;
  // authMethods: ("password" | "mfa")[];
  // clientId: string;
  // ipAddress: string;
  // tenantId: string;
  // userId: string;
  groups: string[];
  scopes: string;
};

type RawProfile = {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  // acr: string;
  // aio: string;
  // amr: string[];
  // appid: string;
  // appidacr: string;
  // eslint-disable-next-line camelcase
  family_name: string;
  // eslint-disable-next-line camelcase
  given_name: string;
  // ipaddr: string;
  // name: string;
  // oid: string;
  scp: string;
  // sub: string;
  // tid: string;
  // eslint-disable-next-line camelcase
  // unique_name: string;
  emails: string[];
  groups: string[];
  // uti: string;
  ver: string;
};

// aud: "37755387-0ff5-4bff-ad38-44981af5e812"
// exp: 1594049331
// iat: 1594045731
// iss: "https://cdsaireon.b2clogin.com/82881933-eae6-41bb-9755-1776c5767a17/v2.0/"
// nbf: 1594045731
// scp: "API.Access"
// ver: "1.0"

// azp: "84b54f26-6329-489d-8924-6c78a9bfabee"
// nonce: "10b6a9af-381a-49b1-9e7a-cb8e0b1eb597"
// sub: "63b9c4aa-0f00-4e61-85dc-9a6ea8ec17ad"
// tfp: "B2C_1_BetaSignUp"
function rawToProfile(profile: RawProfile): Profile {
  return {
    apiId: profile.aud,
    issuer: profile.iss,
    issueDateTime: new Date(profile.iat * 1000),
    notBeforeDateTime: new Date(profile.nbf * 1000),
    expiryDateTime: new Date(profile.exp * 1000),
    familyName: profile.family_name,
    givenName: profile.given_name,
    email: profile.emails?.find((email) => email !== undefined),
    // authMethods: profile.amr.map((v) => (v === "mfa" ? "mfa" : "password")),
    // clientId: profile.appid,
    // ipAddress: profile.ipaddr,
    // name: profile.name,
    // tenantId: profile.tid,
    // uniqueName: profile.unique_name,
    // userId: profile.oid,
    groups: profile.groups || [],
    scopes: profile.scp,
  };
}

export function profileFromToken(accessToken: string): Profile {
  // https://docs.microsoft.com/en-us/azure/active-directory/develop/access-tokens
  const decoded = decode<RawProfile>(accessToken);
  return rawToProfile(decoded);
}
