/* eslint-disable @typescript-eslint/no-empty-function */
import * as Msal from "msal";
import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { Button } from "../../components/Button";
import { baseUrl } from "../api/index";
import { ApiEndpoint } from "../api/Endpoints";
import { Profile, profileFromToken } from "./Profile";
import { AuthError, AuthResponse } from "msal";
import { ENV } from "../constants/environment";

const resetPasswordConfig = {
  authority: process.env.REACT_APP_RESET_PASSWORD_AUTHORITY,
};

const msalConfig: Msal.Configuration = {
  auth: {
    clientId: ENV.clientId,
    authority: ENV.authority,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    tokenRenewalOffsetSeconds: 1800,
  },
};

const loginRequest = {
  scopes: ENV.scopes,
};
const tokenRequest = {
  scopes: ENV.scopes,
};
const refreshIdTokenRequest = {
  scopes: [ENV.clientId],
};

export type AuthStatus =
  | "uninitialized"
  | "signedout"
  | "signingin"
  | "error"
  | "signedin";

export type AuthContextType = {
  status: AuthStatus;
  error: string | null;
  fetch:
    | ((input: Request | ApiEndpoint, init?: RequestInit) => Promise<Response>)
    | null;
  signin: () => void;
  signout: () => void;
  profile: Profile | null;
  accessToken: string | null;
  hasForgottenPassword: boolean;
};

type DesiredSignInState = "signedin" | "signedout" | "error" | null;

const defaultAuthContext: AuthContextType = {
  status: "uninitialized",
  error: null,
  fetch: null,
  signin: () => {},
  signout: () => {},
  profile: null,
  accessToken: null,
  hasForgottenPassword: false,
};

export const AuthContext = React.createContext(defaultAuthContext);

function authorizedFetch(accessToken: string) {
  return (input: Request | ApiEndpoint, init?: RequestInit) => {
    const headers = new Headers(init?.headers);
    headers.append("Authorization", `Bearer ${accessToken}`);
    let options = init;
    if (options == null) {
      options = {
        headers,
      };
    } else {
      options.headers = headers;
    }
    let requestInfo: RequestInfo;
    if ("toUrl" in input) {
      requestInfo = input.toUrl();
    } else {
      // input is Request
      requestInfo = new Request({
        ...input,
        url: `${baseUrl}${input.url as string}`,
      });
    }
    return fetch(requestInfo, options);
  };
}

type LoginParams = {
  msalInstance: Msal.UserAgentApplication;
  setAccessToken: (value: React.SetStateAction<string>) => void;
  initialized: boolean;
  setInitialized: (value: React.SetStateAction<boolean>) => void;
  setStatus: (value: React.SetStateAction<AuthStatus>) => void;
  setProfile: (value: React.SetStateAction<Profile | null>) => void;
  setDesiredSignInState: (
    value: React.SetStateAction<DesiredSignInState>,
  ) => void;
  setHasForgottenPassword?: (value: React.SetStateAction<boolean>) => void;
};

function loginRedirect({
  msalInstance,
  setAccessToken,
  initialized,
  setInitialized,
  setStatus,
  setProfile,
  setDesiredSignInState,
  setHasForgottenPassword,
}: LoginParams) {
  msalInstance.handleRedirectCallback(
    // eslint-disable-next-line no-unused-vars
    (response: AuthResponse) => {
      // console.info(`Got Auth Response: ${response}`);
    },
    // eslint-disable-next-line no-unused-vars
    (authError: AuthError, accountState: string) => {
      // Password reset policy/authority
      // This feature doesn't currently work, we had to use this workaround: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1245
      if (authError && authError.errorMessage.indexOf("AADB2C90118") > -1) {
        try {
          if (setHasForgottenPassword) setHasForgottenPassword(true);
          msalInstance.loginRedirect(resetPasswordConfig);
        } catch (err) {
          console.error(err);
        }
      }
    },
  );
  msalInstance
    .acquireTokenSilent(tokenRequest)
    .then((response) => {
      setAccessToken(response.accessToken);
      setInitialized(true);
      setStatus("signedin");
      setProfile(profileFromToken(response.accessToken));
    })
    .catch((error) => {
      if (
        (error.name &&
          error.name.indexOf("InteractionRequiredAuthError") !== -1) ||
        error.errorMessage.indexOf("interaction_required") !== -1
      ) {
        msalInstance.acquireTokenRedirect(tokenRequest);
        return;
      }
      if (!initialized) {
        throw error;
      }
      if (error.name && error.name === "ClientAuthError") {
        msalInstance.loginRedirect(loginRequest);
      }
    })
    .catch((/* error */) => {
      if (!initialized) {
        setInitialized(true);
        setStatus("signedout");
      } else {
        setStatus("error");
        setDesiredSignInState("error");
      }
    });
}

function loginPopup({
  msalInstance,
  setAccessToken,
  initialized,
  setInitialized,
  setStatus,
  setProfile,
  setDesiredSignInState,
}: LoginParams) {
  msalInstance
    .acquireTokenSilent(tokenRequest)
    .catch((error) => {
      if (!initialized) {
        throw error;
      }
      console.error(error);
      return msalInstance
        .loginPopup(loginRequest)
        .then(() => msalInstance.acquireTokenSilent(tokenRequest))
        .catch((error2) => {
          if (!initialized) {
            throw error2;
          }
          if (error.errorMessage.indexOf("interaction_required") === -1) {
            throw error2;
          }
          return msalInstance.acquireTokenPopup(tokenRequest);
        });
    })
    .then((response) => {
      setAccessToken(response.accessToken);
      setInitialized(true);
      setStatus("signedin");
      setProfile(profileFromToken(response.accessToken));
    })
    .catch((/* error */) => {
      if (!initialized) {
        setInitialized(true);
        setStatus("signedout");
      } else {
        setStatus("error");
        setDesiredSignInState("error");
      }
    });
}

export const AuthProvider: FunctionComponent = ({ children }) => {
  const [status, setStatus] = useState<AuthStatus>("uninitialized");
  const [error, setError] = useState<string | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [desiredSignInState, setDesiredSignInState] = useState<
    DesiredSignInState
  >(null);
  const [accessToken, setAccessToken] = useState<string>("signedout");
  const [profile, setProfile] = useState<Profile | null>(null);
  const [hasForgottenPassword, setHasForgottenPassword] = useState<boolean>(
    false,
  );

  useEffect(() => {
    const msalInstance = new Msal.UserAgentApplication(msalConfig);
    if (!initialized || desiredSignInState === "signedin") {
      if (initialized) {
        setStatus("signingin");
      }
      if (process.env.REACT_APP_LOGIN_MODE === "popup") {
        loginPopup({
          msalInstance,
          setAccessToken,
          initialized,
          setInitialized,
          setStatus,
          setProfile,
          setDesiredSignInState,
        });
      } else {
        loginRedirect({
          msalInstance,
          setAccessToken,
          initialized,
          setInitialized,
          setStatus,
          setProfile,
          setDesiredSignInState,
          setHasForgottenPassword,
        });
      }
    } else if (desiredSignInState === "signedout") {
      // Do signout here
      msalInstance.logout();
    }
  }, [desiredSignInState, initialized]);

  useEffect(() => {
    if (status !== "signedin") {
      return;
    }
    const timer = setInterval(() => {
      const msalInstance = new Msal.UserAgentApplication(msalConfig);
      return msalInstance
        .acquireTokenSilent(refreshIdTokenRequest)
        .then(() => msalInstance.acquireTokenSilent(tokenRequest))
        .then((response) => {
          setAccessToken(response.accessToken);
          setInitialized(true);
          setStatus("signedin");
          setProfile(profileFromToken(response.accessToken));
        })
        .catch((error2) => {
          const logoutErrors = [
            "invalid_id_token",
            "invalid_state_error",
            "user_login_error",
            "user_non_existent",
            "null_or_empty_id_token",
            "id_token_parsing_error",
            "token_encoding_error",
            "token_renewal_error",
          ];
          if (logoutErrors.indexOf(error2?.errorCode ?? "") >= 0) {
            setStatus("error");
            setDesiredSignInState("error");
            setError(
              `An authentication error has occurred (${error2?.errorCode}). Please sign in again to continue.`,
            );
          }
        });
    }, ENV.refreshInterval);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [status]);

  const authState = {
    status,
    error,
    hasForgottenPassword,
    profile: status === "signedin" ? profile : null,
    fetch: status === "signedin" ? authorizedFetch(accessToken) : null,
    signin: () => {
      setDesiredSignInState("signedin");
    },
    signout: () => {
      setDesiredSignInState("signedout");
    },
    accessToken: status === "signedin" ? accessToken : null,
  };

  return (
    <div>
      <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
    </div>
  );
};

export function SignInButton(props: React.HTMLProps<HTMLInputElement>) {
  const auth = useContext(AuthContext);
  return <Button onClick={() => auth.signin()} value="Sign In" {...props} />;
}
