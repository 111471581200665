import Http from "../utils/http";
import { handleError } from "../utils/httpError";

abstract class Request {
  protected static get<T>(uri: string, accessToken: string | null): Promise<T> {
    return Http.get<T>(`${uri}`, accessToken).catch(Request.handleError);
  }

  protected static getApim<T>(
    uri: string,
    accessToken: string | null,
  ): Promise<T> {
    return Http.getApim<T>(`${uri}`, accessToken).catch(Request.handleError);
  }

  protected static post<T>(
    uri: string,
    body: any,
    accessToken: string | null,
  ): Promise<T> {
    return Http.post<T>(`${uri}`, body, accessToken).catch(Request.handleError);
  }

  private static handleError(error: any): any {
    if (error instanceof Response) {
      throw handleError(error.status);
    }

    throw error;
  }
}

export default Request;
